<template>
  <div class="container-fluid">
    <div class="page-title">
      <i class="fa-regular fa-coins"></i>
      <h1>{{ $t("COMMON.PURCHASES_PAYMENTS") }}</h1>
    </div>
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("PURCHASES_PAYMENTS.PURCHASES_PAYMENTS_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openPurchasesPaymentCreateModal"
              v-if="
                $currentUserCan($permissions.PERM_CREATE_PURCHASES_PAYMENTS)
              "
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("PURCHASES_PAYMENTS.ADD_PURCHASES_PAYMENT") }}
              </span>
            </base-button>
            <!--<notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_PURCHASES_PAYMENTS)"
              :objectType="'PurchasesPayments'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />-->
          </div>
        </div>
      </div>

      <purchases-payment-list-table
        @onViewPurchasesPayment="openPurchasesPaymentViewModal"
        @onEditPurchasesPayment="openPurchasesPaymentEditModal"
        @onDeletePurchasesPayment="deletePurchasesPayment"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewPurchasesPaymentModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewPurchasesPaymentModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_PAYMENT'"
          @onCloseModal="closePurchasesPaymentModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PURCHASES_PAYMENTS.VIEW_PURCHASES_PAYMENT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openPurchasesPayment"
                :objectType="'PurchasesPayments'"
                :objectId="openPurchasesPayment.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openPurchasesPaymentEditModal(openPurchasesPayment)"
                  v-if="
                    openPurchasesPayment.status == PAYMENT_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_EDIT_PURCHASES_PAYMENTS)
                  "
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="edit"
                  @click="deletePurchasesPayment(openPurchasesPayment)"
                  v-if="
                    openPurchasesPayment.status == PAYMENT_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_DELETE_PURCHASES_PAYMENTS)
                  "
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closePurchasesPaymentModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-purchases-payment-page
              v-if="openPurchasesPayment"
              :purchasesPaymentId="openPurchasesPayment.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditPurchasesPaymentModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditPurchasesPaymentModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_PAYMENT'"
          @onCloseModal="closePurchasesPaymentModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PURCHASES_PAYMENTS.EDIT_PURCHASES_PAYMENT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button
                class="close"
                @click="openPurchasesPaymentViewModal(openPurchasesPayment)"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-purchases-payment-page
              v-if="openPurchasesPayment"
              :purchasesPaymentId="openPurchasesPayment.id"
              @onViewPurchasesPayment="openPurchasesPaymentViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddPurchasesPaymentModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddPurchasesPaymentModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PURCHASES_PAYMENT'"
          @onCloseModal="closePurchasesPaymentModal"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("PURCHASES_PAYMENTS.ADD_PURCHASES_PAYMENT") }}
            </h1>
            <button class="close" @click="closePurchasesPaymentModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-purchases-payment-page
              @onViewPurchasesPayment="openPurchasesPaymentViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { PAYMENT_STATUS_DRAFT } from "@/constants/payments";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import PurchasesPaymentListTable from "./partials/PurchasesPaymentListTable.vue";
import EditPurchasesPaymentPage from "./components/EditPurchasesPaymentComponent.vue";
import AddPurchasesPaymentPage from "./components/AddPurchasesPaymentComponent.vue";
import ViewPurchasesPaymentPage from "./components/ViewPurchasesPaymentComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    PurchasesPaymentListTable,
    NotificationSubscription,
    EditPurchasesPaymentPage,
    AddPurchasesPaymentPage,
    ViewPurchasesPaymentPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewPurchasesPaymentModalOpened = false;
    let isEditPurchasesPaymentModalOpened = false;
    let isAddPurchasesPaymentModalOpened = false;
    let openPurchasesPayment = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewPurchasesPaymentModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditPurchasesPaymentModalOpened = true;
      }
      openPurchasesPayment = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddPurchasesPaymentModalOpened = true;
    }
    return {
      isViewPurchasesPaymentModalOpened: isViewPurchasesPaymentModalOpened,
      isEditPurchasesPaymentModalOpened: isEditPurchasesPaymentModalOpened,
      isAddPurchasesPaymentModalOpened: isAddPurchasesPaymentModalOpened,
      openPurchasesPayment: openPurchasesPayment,
      renderKey: 1,
      PAYMENT_STATUS_DRAFT: PAYMENT_STATUS_DRAFT,
    };
  },

  methods: {
    openPurchasesPaymentCreateModal() {
      this.closePurchasesPaymentModal();
      this.isAddPurchasesPaymentModalOpened = true;

      this.$router.replace({
        name: "List PurchasesPayments",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },
    openPurchasesPaymentViewModal(purchasesPayment, reRender = false) {
      this.closePurchasesPaymentModal();
      this.openPurchasesPayment = purchasesPayment;
      this.isViewPurchasesPaymentModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List PurchasesPayments",
        query: {
          id: this.openPurchasesPayment.id,
          action: QUERY_ACTIONS_VIEW,
        },
      });
    },
    openPurchasesPaymentEditModal(purchasesPayment) {
      this.closePurchasesPaymentModal();
      this.openPurchasesPayment = purchasesPayment;
      this.isEditPurchasesPaymentModalOpened = true;

      this.$router.replace({
        name: "List PurchasesPayments",
        query: {
          id: this.openPurchasesPayment.id,
          action: QUERY_ACTIONS_EDIT,
        },
      });
    },
    closePurchasesPaymentModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }
      this.isAddPurchasesPaymentModalOpened = false;
      this.isViewPurchasesPaymentModalOpened = false;
      this.isEditPurchasesPaymentModalOpened = false;
      this.openPurchasesPayment = null;

      if (
        this.$route.name !== "List PurchasesPayments" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List PurchasesPayments",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    async deletePurchasesPayment(purchasesPayment) {
      const confirmation = await swal.fire({
        title: this.$t("PURCHASES_PAYMENTS.DELETE_THIS_PURCHASES_PAYMENT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch(
            "purchasesPayments/destroy",
            purchasesPayment.id
          );
          this.renderKey++;
          this.closePurchasesPaymentModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("PURCHASES_PAYMENTS.PURCHASES_PAYMENT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
